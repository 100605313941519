import React, { useEffect, useState } from 'react';
import { Tabs, Modal, Button } from 'antd';
import "./TemplateView.scss";
import CreditAssessService from './components/CreditAssessService';
import ConfirmDetails from './components/ConfirmDetails';
import TermsConditions from './components/TermsConditions';
import { RotatingLines } from 'react-loader-spinner';
import { getMethod } from '../../utils/services/api';
import url from "../../utils/services/url.json";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from 'react-redux';
import { actionUserdetails } from "../../redux/otpVerificationDetails/action";
const TemplateView = () => {

    const TabPane = Tabs.TabPane;
    const [checkboxStatus, setCheckboxStatus] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeKey, setActiveKey] = useState("1");
    const [loader, setLoader] = useState(true);
    const [logoChange,setLogoChange]=useState('')
    const dispatch = useDispatch();
    const param = useParams();
    const navigate = useNavigate();
    const location  = window.location.origin
    const onChange = (e) => {
        setActiveKey(e);
    }

    const handleCheckBox = (value) => {
        setCheckboxStatus(!value);
    }

    const handleClickCheck = (value) => {
        setActiveKey(value);
    }

    const handleClickNext = (value) => {
        setActiveKey(value);
    }

    setTimeout(() => { setLoader(false) }, 2000);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        handleLogo();
        handleConfirm();
    }, []);

    const handleLogo = async () => {
        const logo = await getMethod(url.logo + location);
        if(logo && logo.data.length){
            setLogoChange(logo.data[0].logo)
        }
       
    }
    const handleConfirm = async () => {
        const result = await getMethod(url.verifyOtp + param.token);
        if (result && result.status === 200) {
            // navigate("/templateView");
            dispatch(actionUserdetails(result));
            localStorage.setItem("getDetail", JSON.stringify(result));
        }
    }
    return (
        <div className='c2c_templateView'>
            <div className="subContainer">

                <div className="templateView">
                    <div className="box">

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {/* <span className="c2c_logo" style={{ width: 140 }}>
                            </span> */}
                            <div >
                            {logoChange?<img src={logoChange} className="c2c_logo"></img>:<img src="https://uat-front.click2check.com/images/logoBeta.png" className="c2c_logo"></img>}
                            </div>
                        </div>

                        <div className='tabs' style={{ margin: "10px 20px 0px 20px" }}>
                            <div className='ico_set_logoaf' style={{ display: "flex", justifyContent: "end", marginBottom: "10px", cursor: "pointer" }}>
                                <img src={"https://uat-front.click2check.com/images/c2c-help-icon.png"} onClick={() => showModal()} />
                            </div>

                            <Tabs type="card" defaultActiveKey="1" activeKey={activeKey} onChange={(e) => onChange(e)}>
                                <TabPane tab="About The Credit Assess Service" key="1">
                                    <CreditAssessService onclickCheck={(data) => handleClickCheck(data)} />
                                </TabPane>
                                <TabPane tab="Terms & conditions" key="2">
                                    <TermsConditions onCheckbox={(data) => handleCheckBox(data)} CheckClickNext={(data) => handleClickNext(data)} />
                                </TabPane>
                                <TabPane tab="Confirm Your Details" key="3" disabled={checkboxStatus}>
                                    <ConfirmDetails />
                                </TabPane>
                            </Tabs>
                        </div>

                        <Modal open={isModalOpen} width={600} onOk={handleOk} onCancel={handleCancel} style={{ top: 25 }} footer={[<Button key="back" onClick={handleCancel}>OK</Button>]}>
                            <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                                This section contains three tabs. These tabs contain the following information and requirements.
                            </p>

                            <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                                Tab 1 – About the Credit Assess Service. This tab provides you with an overview of the Credit Assess service. You should read this carefully before proceeding.
                            </p>

                            <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                                Tab 2 – Terms & Conditions. This tab contains the service Terms & Conditions and Privacy Policy. You are able to print and/or save a copy of this.
                                You will be required to confirm your agreement to these Terms & Conditions and Privacy Policy before you can proceed with the service
                            </p>

                            <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                                Tab 3 – Tell Us About Yourself. This tab contains information on you and the transaction surrounding the advice you are receiving.
                                Some, if not all of this information will have been completed by your adviser. Please add any information that is missing.
                                If you need to add your current address please enter your postcode and use the “Find” button. This will deliver address options associated with your postcode.
                                You will then need to choose the correct address option.
                            </p>

                            <p style={{ marginTop: "0px", marginBottom: "10px" }}>
                                On completion of the above stages please use the “Confirm” button at the bottom of the page.
                                This will start the Credit Assess Service. This process includes the delivery onscreen of a number of security questions which have been created from the content of your credit file.
                                These are multiple choice questions and are aimed at ensuring that only you can access your credit file.
                                Please ensure that you answer these questions as accurately as possible and if necessary, consult any documentation that you may have.
                                At the end of the process your Credit File will be delivered to you for either printing and/or saving to your local drive.
                                A copy will be made available to your adviser to assist him in providing you with the most appropriate advice.
                            </p>
                        </Modal>

                    </div>
                </div>

                <div style={{ position: "fixed", bottom: "-8px", width: "100%" }}>
                    <p style={{ display: "flex", justifyContent: "center", color: "#7b7b7b", fontSize: "14px", fontFamily:'Arial' }}>
                        Powered by Click2Check
                    </p>
                </div>

            </div>

            {loader &&
                <div className="loader" >
                    <RotatingLines
                        strokeColor="black"
                        strokeWidth="3"
                        animationDuration="0.75"
                        width="96"
                        visible={true}
                    />
                    <p> <strong>Please wait...</strong></p>
                </div>
            }

        </div>
    );
};

export default TemplateView;