import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ThankYou.scss";
import { RotatingLines } from 'react-loader-spinner';
import { getMethod } from "../../utils/services/api";
import url from "../../utils/services/url.json";
import { useDispatch } from "react-redux";
export default function Step2() {
//    setTimeout(()=>{
//     setLoader(false)
//    },30000)
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const[detail,setDetail]=useState()
    const dispatch = useDispatch();
    
    useEffect(() => {
        // setTimeout(()=>{
        //     setLoader(false)
        //     handleCaseLookup(1)
        //    },10000)
            // handleCaseLookup();
            startCaseLookup()
    },[]);

    const startCaseLookup = async()=>{
        let getDetail =localStorage.getItem('getDetail')
        getDetail=JSON.parse(getDetail)
        setDetail(getDetail)
        const leadId=getDetail.data.uid
        const status = await getMethod(url.caseLookup + leadId + "/");
        if(status && status.data.success === true){
            setTimeout(()=>{
                navigate("/proceedView");
            }, 2000)
        }else{
          setTimeout(()=>{
            // setLoader(false)
            handleCaseLookup(1)
           },10000)
        }
    }
    const handleCaseLookup = async (counter) => {
        let getDetail =localStorage.getItem('getDetail')
        getDetail=JSON.parse(getDetail)
        setDetail(getDetail)
        const leadId=getDetail.data.uid
        const status = await getMethod(url.caseLookup + leadId + "/");
        if(getDetail.data.leadCreditKudos===1 && getDetail.data.leadCreditScore===0){
            setTimeout(()=>{
                navigate("/proceedView");
                }, 2000)
        }
        else{
            if(status && status.data.success === false &&  counter<3){
                setLoader(true);
                setTimeout(()=>{
                    // setLoader(false)
                    handleCaseLookup(counter+1)
                   },10000)   
            }
            else if(status && status.data.success === true){
                setTimeout(()=>{
                navigate("/proceedView");
                }, 2000)
                // dispatch(handleCaseLookup(status.uid));
                localStorage.setItem("caseDetail", JSON.stringify(status));
            }
            else if(status && status.data.success === false &&  counter>2){
                setLoader(true);
                    setTimeout(() => {
                        getData(1);
                        // setLoader(false);
                    }, 10000);
            }
        }
    }
    const getData = async(count) => {
        let getDetail =localStorage.getItem('getDetail')
        getDetail=JSON.parse(getDetail)
        setDetail(getDetail)
        const leadId=getDetail.data.uid
        const status = await getMethod(url.caseLookup + leadId + "/");
        if(status && status.data.success==false && count<3){
            setLoader(true);
            setTimeout(() => {
                getData(count+1);
                // setLoader(false);
            }, 10000);
        }else{
            setTimeout(()=>{
                navigate("/proceedView");
                }, 2000)
            localStorage.setItem("caseDetail", JSON.stringify(status));
        }
    }

    return (
        <div className="c2c_thankYou">
            <div className="subContainer">

                <div className="thankYou">
                    <div className="box">

                        <div className="heading">
                            <h2 className="title">{detail ? `${detail.data.firstName} ${detail.data.lastName}` : ''}</h2>
                        </div>

                        <div className="heading">
                            <p className="text">
                                Thank you for using Credit Assess service. please wait while we fetch your {detail ? (detail.data.leadCreditScore && !detail.data.leadCreditKudos)?'Credit Report':(!detail.data.leadCreditScore && detail.data.leadCreditKudos)?'Bank Report':'Credit and Bank Report':''}.
                            </p>
                        </div>

                        <div className="heading">
                            <p className="text">
                                Please do not shut the window down while processing as it will not return the Report(s)!!
                            </p>
                        </div>

                        <div className="heading">
                            <p className="text">
                                This may take a few minutes.
                            </p>
                        </div>

                    </div>
                </div>

            </div>

            <div style={{ position: "fixed", bottom: "-8px", width: "100%" }}>
                <p style={{ display: "flex", justifyContent: "center", color: "#7b7b7b", fontSize: "14px",fontFamily:'Arial' }}>
                    Powered by Click2Check
                </p>
            </div>

            {loader &&
                <div className="loader" >
                    <RotatingLines
                        strokeColor="black"
                        strokeWidth="3"
                        animationDuration="1.75"
                        width="96"
                        visible={true}
                    />
                    <p> <strong>Please wait...</strong></p>
                </div>
            }

        </div>
    )
}
